<template>
  <LayoutWithoutMap>
    <template #body>
      <div class="w-full">
        <div>
          <PromyTabs
            :tabs="list_estimated_price"
            class="mt-1"
            v-if="is_estimated_price_exist"
          >
            <template v-slot="{ current_tab }">
              <div
                v-for="(item, indice) in list_estimated_price"
                :key="`item-${indice}`"
                v-show="item.component === current_tab.component"
              >
                <div
                  class="text-xl font-main font-extrabold text-promy-purple-400"
                >
                  {{ item.title }}
                </div>
                <div>
                  <div v-for="(cat, index) in item.categories" :key="index">
                    <div class="pb-12">
                      <div
                        class="flex items-center justify-between pt-4 mb-4 sm:space-x-2"
                      >
                        <span
                          class="text-promy-purple-400 font-bold text-base sm:text-sm"
                        >
                          {{ cat.title }}</span
                        >
                        <span
                          class="rounded-md px-2 py-4 font-bold sm:text-sm"
                          :style="`background-color: ${cat.bg_color_confidence};color:${cat.text_color_confidence}`"
                        >
                          Échantillon: {{ cat.statut_confidence }}
                        </span>
                      </div>

                      <div class="relative mb-4" style="width: 99%">
                        <canvas
                          :id="cat.id_chart"
                          :height="getHeightCanvas()"
                        ></canvas>
                      </div>
                      <div class="pr-[34px] pl-[40px] lg:px-0">
                        <div
                          class="border rounded bg-promy-blue-270 bg-opacity-10 mt-10"
                        >
                          <div
                            class="flex justify-between items-center py-6 px-5 sm:flex-col sm:items-start sm:justify-start sm:space-y-3 sm:p-4"
                          >
                            <div class="flex items-center space-x-5 font-bold">
                              <div
                                class="flex flex-col pl-4 pr-12 py-2 rounded bg-promy-blue-270 text-white"
                              >
                                <span class="font-semibold">Prix minimum </span>
                                <span
                                  class="font-semibold text-promy-orange-200"
                                  >{{ cat.priceMinValue }}</span
                                >
                              </div>
                              <div
                                class="flex flex-col pl-4 pr-12 py-2 rounded bg-promy-blue-270 text-white"
                              >
                                <span class="font-semibold">Prix maximum </span>
                                <span
                                  class="font-semibold text-promy-orange-200"
                                >
                                  {{ cat.priceMaxValue }}</span
                                >
                              </div>
                              <div
                                class="flex flex-col pl-4 pr-12 py-2 rounded bg-promy-blue-270 text-white"
                              >
                                <span class="font-semibold"
                                  >Évolution en pourcentage
                                </span>
                                <span
                                  class="font-semibold text-promy-orange-200"
                                >
                                  {{ getPercentageChange(cat.values) }}</span
                                >
                              </div>
                            </div>
                            <div
                              class="flex flex-col justify-center sm:ml-auto text-promy-blue-270"
                            >
                              <div class="flex flex-col">
                                <span class="text-right text-sm"
                                  >Prix Moyen
                                </span>
                                <span class="font-bold text-xl">
                                  {{ cat.priceMeanValue }}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </PromyTabs>
          <div class="flex flex-col w-full flex-1" v-else>
            <empty-state
              class="h-4/5 mt-7 bg-transparent"
              :message="'Informations d\'évolution des prix non disponibles'"
              :main_icon="'bot1'"
              classes_icon="!w-1/4 !h-1/4 sm:!w-48 sm:!h-48"
            />
          </div>
        </div>
      </div>
    </template>
  </LayoutWithoutMap>
</template>
<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import LayoutWithoutMap from '../LayoutWithoutMap'

export default {
  components: {
    LayoutWithoutMap,
  },
  data() {
    return {
      current_tab: 'prixAncien',
      is_estimated_price_exist: true,

      list_estimated_price: [
        {
          is_active: true,
          label: 'Prix ancien',
          component: 'prixAncien',
          title: "Analyse du marché dans l'immobilier ancien",
          categories: [
            {
              id: 16210,
              title: 'Évolution globale des prix',
              id_chart: 'prix-ancien-global',
            },
            {
              id: 16211,
              title: 'Évolution du prix des appartements',
              id_chart: 'prix-ancien-appartement',
            },
            {
              id: 16212,
              title: 'Évolution du prix des maisons',
              id_chart: 'prix-ancien-maison',
            },
          ],
        },
        {
          is_active: false,
          label: 'Prix neuf',
          component: 'prixNeuf',
          title: "Analyse du marché dans l'immobilier neuf",
          categories: [
            {
              id: 16220,
              title: 'Évolution globale des prix',
              id_chart: 'prix-neuf-global',
            },
            {
              id: 16221,
              title: 'Évolution du prix des appartements',
              id_chart: 'prix-neuf-appartement',
            },
            {
              id: 16222,
              title: 'Évolution du prix des maisons',
              id_chart: 'prix-neuf-maison',
            },
          ],
        },
        {
          is_active: false,
          label: 'Prix location',
          component: 'prixLocation',
          title: 'Analyse du marché de la location',
          categories: [
            {
              id: 16230,
              title: 'Évolution globale des loyers',
              id_chart: 'prix-loyers-global',
            },
            {
              id: 16231,
              title: 'Évolution des loyers en appartement',
              id_chart: 'prix-loyers-appartement',
            },
            {
              id: 16232,
              title: 'Évolution des loyers en maison',
              id_chart: 'prix-loyers-maison',
            },
          ],
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      poi: 'address/getPOI',
    }),
  },
  created() {
    this.is_estimated_price_exist =
      this.poi.data.prix && this.poi.data.prix.length

    if (this.is_estimated_price_exist) {
      this.list_estimated_price.forEach((item) => {
        item.categories.forEach((cat) => {
          cat.labels = []
          cat.values = []
          let temp_cat = this.poi.data.prix.find((elem) => elem.id === cat.id)
          if (temp_cat) {
            cat.priceMaxValue = this.formatEUR(
              temp_cat.characteristics.priceMaxValue,
              '/㎡',
            )
            cat.priceMinValue = this.formatEUR(
              temp_cat.characteristics.priceMinValue,
              '/㎡',
            )
            cat.priceMeanValue = this.formatEUR(
              temp_cat.characteristics.priceMeanValue,
              '/㎡',
            )
            if (parseFloat(temp_cat.characteristics.confidence) >= 0.5) {
              cat.statut_confidence = 'Bon'
              cat.text_color_confidence = '#37C633'
              cat.bg_color_confidence = '#DFFFDE'
            } else {
              cat.statut_confidence = 'Faible'
              cat.text_color_confidence = '#C63333'
              cat.bg_color_confidence = '#FFDEDE'
            }
            Object.keys(temp_cat.characteristics.priceTrends)
              .sort(function (a, b) {
                return (
                  moment(a, 'YYYY-MM-DD').toDate() -
                  moment(b, 'YYYY-MM-DD').toDate()
                )
              })
              .forEach(function (key) {
                cat.labels.push(key)
                cat.values.push(temp_cat.characteristics.priceTrends[key].value)
              })
          }
        })
      })
    }
  },
  mounted() {
    if (this.is_estimated_price_exist) {
      this.addLinesChart()
    }
  },
  methods: {
    getHeightCanvas() {
      return window.innerWidth >= 768 ? 100 : 150
    },
    formatEUR(value, prefix = '') {
      const euroformatter = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })

      return euroformatter.format(parseFloat(value)) + prefix
    },
    getGradient(ctx, chartArea) {
      const gradient = ctx.createLinearGradient(0, 0, 0, 430)
      gradient.addColorStop(0, 'rgba(62, 77, 154, 0.2)')
      gradient.addColorStop(1, 'rgba(62, 77, 154, 0)')

      return gradient
    },
    addLinesChart() {
      this.list_estimated_price.forEach((item) => {
        item.categories.forEach((cat) => {
          let canvas = document.getElementById(cat.id_chart).getContext('2d')
          new this.$chart(canvas, {
            type: 'line',
            data: {
              labels: cat.labels,
              datasets: [
                {
                  backgroundColor: '#3e4d9a',
                  pointBorderWidth: 3,
                  borderColor: '#3e4d9a',
                  fill: true,
                  data: cat.values,
                  backgroundColor: (context) => {
                    const chart = context.chart
                    const { ctx, chartArea } = chart
                    if (!chartArea) {
                      return
                    }
                    return this.getGradient(ctx, chartArea)
                  },
                },
              ],
            },
            options: {
              devicePixelRatio: 4,
              responsive: true,
              legend: {
                display: false,
              },
              scales: {
                xAxes: {
                  display: true,
                  ticks: {
                    fontSize: 12,
                    maxTicksLimit: window.innerWidth >= 768 ? 10 : 3,
                    maxRotation: 0,
                    minRotation: 0,
                  },
                },

                yAxes: {
                  beginAtZero: true,
                  display: true,
                  ticks: {
                    fontSize: 12,
                  },
                },
              },
            },
            plugins: [
              {
                id: 'chartArea',
                beforeDraw(chart, args, options) {
                  const {
                    ctx,
                    chartArea: { top, right, bottom, left, width, height },
                    scales: { x, y },
                  } = chart
                  ctx.save()
                  ctx.globalCompositeOperation = 'destination-over'
                  ctx.fillStyle = '#FFFFFF'

                  ctx.fillRect(left, top, width, height)
                  ctx.restore()
                },
              },
            ],
          })
        })
      })
    },
    getPercentageChange(values) {
      try {
        return (
          Math.round(
            ((values[values.length - 1] - values[0]) / values[0]) * 100,
          ) + '%'
        )
      } catch (err) {
        return 'N.C'
      }
    },
  },
}
</script>
